
var service = {};

var token = JSON.parse(localStorage.getItem('token'));
var userData = JSON.parse(localStorage.getItem('user'));

export var setToken = (tokenData) => {
    return token = tokenData;
}

export var getToken = () => {
    return token;
}

export var setUserData = (user) => {
    return userData = user;
}

export var getUserDetails = () => {
    return userData;
}

export var logout = () => {
    let orgId = localStorage.getItem("orgId")
    localStorage.clear();
    if (orgId == 99 && window.location.href.includes("admin.assesshub.com")) {
        window.location.replace('/#/auth/jfs/signin')
    } else {
        window.location.replace('/#/auth/signin')
    }
};

export default service;